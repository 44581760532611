<template>
  <div v-if="!isCustomerUser">
    <!--begin::Dashboard-->
    <div class="row">
      <!-- <div class="col-xxl-4">
        <MixedWidget1></MixedWidget1>
      </div>
      <div class="col-xxl-4">
        <ListWidget9></ListWidget9>
      </div>
      <div class="col-xxl-4">
        <StatsWidget7></StatsWidget7>
        <StatsWidget12></StatsWidget12>
      </div>
-->
      <div class="col-xxl-4 order-1 order-xxl-1">
        <!--begin::List Widget 1-->
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0">
            <h3 class="card-title font-weight-bolder text-dark">System Users</h3>
            <!-- <div class="card-toolbar">
        <Dropdown3></Dropdown3>
      </div> -->
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-2">

            <!--begin::Item-->
            <div class="d-flex align-items-center mb-10" >
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-info">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Communication/Shield-user.svg"></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <router-link to="/Users/Users" class="text-dark text-hover-primary mb-1 font-size-lg">
                  <h5>{{ $t("leftMenu.users") }}</h5>
                </router-link>

                <!-- <span class="text-muted">
                {{ item.desc }}
              </span> -->
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->

          </div>
          <!--end::Body-->
        </div>
        <!--end: List Widget 1-->
      </div>
      <div class="col-xxl-4 order-1 order-xxl-1">
        <!--begin::List Widget 1-->
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0">
            <h3 class="card-title font-weight-bolder text-dark">Subscription</h3>
            <!-- <div class="card-toolbar">
        <Dropdown3></Dropdown3>
      </div> -->
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-2">

            <!--begin::Item-->
            <div class="d-flex align-items-center mb-10">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-info">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Communication/Write.svg"></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <router-link to="/registerationManager" class="text-dark text-hover-primary mb-1 font-size-lg">
                  <h5>{{ $t("leftMenu.subscriptionRequests") }}</h5>
                </router-link>

                <!-- <span class="text-muted">
                {{ item.desc }}
              </span> -->
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->

            <!--begin::Item-->
            <div class="d-flex align-items-center mb-10">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-info">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Communication/Write.svg"></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <router-link to="/Subscription/SubscriptionPackage"
                  class="text-dark text-hover-primary mb-1 font-size-lg">
                  <h5>{{ $t("leftMenu.subscriptionList.manageSubscriptionPackage") }}</h5>
                </router-link>

                <!-- <span class="text-muted">
                {{ item.desc }}
              </span> -->
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->

            <!--begin::Item-->
            <div class="d-flex align-items-center mb-10">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-info">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Communication/Write.svg"></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <router-link to="/Subscription/Subscription" class="text-dark text-hover-primary mb-1 font-size-lg">
                  <h5>{{ $t("leftMenu.subscriptionList.manageSubscriptions") }}</h5>
                </router-link>

                <!-- <span class="text-muted">
                {{ item.desc }}
              </span> -->
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->

          </div>
          <!--end::Body-->
        </div>
        <!--end: List Widget 1-->
      </div>

      <div class="col-xxl-4 order-1 order-xxl-1">
        <!--begin::List Widget 1-->
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0">
            <h3 class="card-title font-weight-bolder text-dark">Company Structure</h3>
            <!-- <div class="card-toolbar">
        <Dropdown3></Dropdown3>
      </div> -->
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-2">

            <!--begin::Item-->
            <div class="d-flex align-items-center mb-10">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-info">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Communication/Write.svg"></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <router-link to="/EconomicSector" class="text-dark text-hover-primary mb-1 font-size-lg">
                  <h5>{{ $t("leftMenu.structureLookupList.economicSector") }}</h5>
                </router-link>

                <!-- <span class="text-muted">
                {{ item.desc }}
              </span> -->
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->

            <!--begin::Item-->
            <div class="d-flex align-items-center mb-10">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-info">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Communication/Write.svg"></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <router-link to="/IndustryType" class="text-dark text-hover-primary mb-1 font-size-lg">
                  <h5>{{ $t("leftMenu.structureLookupList.industryType") }}</h5>
                </router-link>

                <!-- <span class="text-muted">
                {{ item.desc }}
              </span> -->
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->

            <!--begin::Item-->
            <div class="d-flex align-items-center mb-10">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-info">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Communication/Write.svg"></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <router-link to="/BusinessSector" class="text-dark text-hover-primary mb-1 font-size-lg">
                  <h5>{{ $t("leftMenu.structureLookupList.businessSector") }}</h5>
                </router-link>

                <!-- <span class="text-muted">
                {{ item.desc }}
              </span> -->
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->

            <!--begin::Item-->
            <div class="d-flex align-items-center mb-10">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-info">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Communication/Write.svg"></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <router-link to="/BusinessSubSector" class="text-dark text-hover-primary mb-1 font-size-lg">
                  <h5>{{ $t("leftMenu.structureLookupList.businessSubSector") }}</h5>
                </router-link>

                <!-- <span class="text-muted">
                {{ item.desc }}
              </span> -->
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->

            <!--begin::Item-->
            <div class="d-flex align-items-center mb-10">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-info">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Communication/Write.svg"></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <router-link to="/JobFamily" class="text-dark text-hover-primary mb-1 font-size-lg">
                  <h5>{{ $t("leftMenu.structureLookupList.jobFamily") }}</h5>
                </router-link>

                <!-- <span class="text-muted">
                {{ item.desc }}
              </span> -->
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->

            <!--begin::Item-->
            <div class="d-flex align-items-center mb-10">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-info">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Communication/Write.svg"></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <router-link to="/Job" class="text-dark text-hover-primary mb-1 font-size-lg">
                  <h5>{{ $t("leftMenu.structureLookupList.jobTitle") }}</h5>
                </router-link>

                <!-- <span class="text-muted">
                {{ item.desc }}
              </span> -->
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->

          </div>
          <!--end::Body-->
        </div>
        <!--end: List Widget 1-->
      </div>


      <div class="col-xxl-4 order-1 order-xxl-1">
        <!--begin::List Widget 1-->
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0">
            <h3 class="card-title font-weight-bolder text-dark">Setup</h3>
            <!-- <div class="card-toolbar">
        <Dropdown3></Dropdown3>
      </div> -->
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-2">

            <!--begin::Item-->
            <div class="d-flex align-items-center mb-10">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-info">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/General/Settings-1.svg"></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <router-link to="/SurveyYear" class="text-dark text-hover-primary mb-1 font-size-lg">
                  <h5>{{ $t("leftMenu.structureLookupList.setupList.surveyYear") }}</h5>
                </router-link>

                <!-- <span class="text-muted">
                {{ item.desc }}
              </span> -->
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->

            <!--begin::Item-->
            <div class="d-flex align-items-center mb-10">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-info">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/General/Settings-1.svg"></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <router-link to="/LegalStructure" class="text-dark text-hover-primary mb-1 font-size-lg">
                  <h5>{{ $t("leftMenu.structureLookupList.setupList.legalStructureLookup") }}</h5>
                </router-link>

                <!-- <span class="text-muted">
                {{ item.desc }}
              </span> -->
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->

          </div>
          <!--end::Body-->
        </div>
        <!--end: List Widget 1-->
      </div>


      <div class="col-xxl-4 order-1 order-xxl-1">
        <!--begin::List Widget 1-->
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0">
            <h3 class="card-title font-weight-bolder text-dark">Market Analysis</h3>
            <!-- <div class="card-toolbar">
        <Dropdown3></Dropdown3>
      </div> -->
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-2">

            <!--begin::Item-->
            <div class="d-flex align-items-center mb-10">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-info">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Home/Stairs.svg"></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <router-link to="/MarketAnalysis/MarketInsights" class="text-dark text-hover-primary mb-1 font-size-lg">
                  <h5>{{ $t("leftMenu.marketAnalysisList.marketInsights") }}</h5>
                </router-link>

                <!-- <span class="text-muted">
                {{ item.desc }}
              </span> -->
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->

            <!--begin::Item-->
            <div class="d-flex align-items-center mb-10">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-info">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Home/Stairs.svg"></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <router-link to="/MarketAnalysis/MarketPayTrends" class="text-dark text-hover-primary mb-1 font-size-lg">
                  <h5>{{ $t("leftMenu.marketAnalysisList.marketPayTrends") }}</h5>
                </router-link>

                <!-- <span class="text-muted">
                {{ item.desc }}
              </span> -->
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->

          </div>
          <!--end::Body-->
        </div>
        <!--end: List Widget 1-->
      </div>


      <div class="col-xxl-4 order-1 order-xxl-1">
        <!--begin::List Widget 1-->
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0">
            <h3 class="card-title font-weight-bolder text-dark">Customer</h3>
            <!-- <div class="card-toolbar">
        <Dropdown3></Dropdown3>
      </div> -->
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-2">

            <!--begin::Item-->
            <div class="d-flex align-items-center mb-10">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-info">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Communication/Shield-user.svg"></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <router-link to="/Customer/Profile" class="text-dark text-hover-primary mb-1 font-size-lg">
                  <h5>{{ $t("leftMenu.customer.profile") }}</h5>
                </router-link>

                <!-- <span class="text-muted">
                {{ item.desc }}
              </span> -->
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->

            <!--begin::Item-->
            <div class="d-flex align-items-center mb-10">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-info">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Communication/Shield-user.svg"></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <router-link to="/Customer/PositionMapping" class="text-dark text-hover-primary mb-1 font-size-lg">
                  <h5>{{ $t("leftMenu.customer.positionMapping") }}</h5>
                </router-link>

                <!-- <span class="text-muted">
                {{ item.desc }}
              </span> -->
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->

            <!--begin::Item-->
            <div class="d-flex align-items-center mb-10">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-info">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Communication/Shield-user.svg"></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <router-link to="/Customer/AnalysisReport" class="text-dark text-hover-primary mb-1 font-size-lg">
                  <h5>{{ $t("leftMenu.customer.analysisReport") }}</h5>
                </router-link>

                <!-- <span class="text-muted">
                {{ item.desc }}
              </span> -->
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->

            <!--begin::Item-->
            <div class="d-flex align-items-center mb-10">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-info">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Communication/Shield-user.svg"></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <router-link to="/Customer/PositionReport" class="text-dark text-hover-primary mb-1 font-size-lg">
                  <h5>{{ $t("leftMenu.customer.positionReport") }}</h5>
                </router-link>

                <!-- <span class="text-muted">
                {{ item.desc }}
              </span> -->
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->

            <!--begin::Item-->
            <div class="d-flex align-items-center mb-10">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-info">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Communication/Shield-user.svg"></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <router-link to="/Customer/ReportGenerator" class="text-dark text-hover-primary mb-1 font-size-lg">
                  <h5>{{ $t("leftMenu.customer.reportGenerator") }}</h5>
                </router-link>

                <!-- <span class="text-muted">
                {{ item.desc }}
              </span> -->
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->

            <!--begin::Item-->
            <div class="d-flex align-items-center mb-10">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-info">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Communication/Shield-user.svg"></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <router-link to="/Customer/CustomerDashboard" class="text-dark text-hover-primary mb-1 font-size-lg">
                  <h5>{{ $t("leftMenu.customer.dashboard") }}</h5>
                </router-link>

                <!-- <span class="text-muted">
                {{ item.desc }}
              </span> -->
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-10">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-info">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Communication/Shield-user.svg"></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <router-link to="/Customer/CustomerReports" class="text-dark text-hover-primary mb-1 font-size-lg">
                  <h5>Reports</h5>
                </router-link>

                <!-- <span class="text-muted">
                {{ item.desc }}
              </span> -->
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->
          </div>
          <!--end::Body-->
        </div>
        <!--end: List Widget 1-->
      </div>
      <!-- <div class="col-xxl-8 order-2 order-xxl-1">
        <AdvancedTableWidget2></AdvancedTableWidget2>
      </div>

      <div class="col-xxl-4 order-1 order-xxl-2">
        <ListWidget3></ListWidget3>
      </div>
      <div class="col-xxl-4 order-1 order-xxl-2">
        <ListWidget4></ListWidget4>
      </div>
      <div class="col-lg-12 col-xxl-4 order-1 order-xxl-2">
        <ListWidget8></ListWidget8>
      </div>-->
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
//import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";
//import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
//import ListWidget1 from "@/view/content/widgets/list/Widget1.vue";
//import ListWidget3 from "@/view/content/widgets/list/Widget3.vue";
//import ListWidget4 from "@/view/content/widgets/list/Widget4.vue";
//import ListWidget8 from "@/view/content/widgets/list/Widget8.vue";
//import ListWidget9 from "@/view/content/widgets/list/Widget9.vue";
//import StatsWidget7 from "@/view/content/widgets/stats/Widget7.vue";
//import StatsWidget12 from "@/view/content/widgets/stats/Widget12.vue";

export default {
  name: "dashboard",
  components: {
    //AdvancedTableWidget2,
    //MixedWidget1,
    //ListWidget1,
    //ListWidget3,
    //ListWidget4,
    //ListWidget8,
    // ListWidget9,
    // StatsWidget7,
    // StatsWidget12
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  }, computed: {
    isCustomerUser() {
      return this.$store.getters.customer != 'No Customer';
    },
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
